<template>
  <div class="page">
    <div class="avatar">
      <el-avatar :src="avatarUrl" size="large"></el-avatar>
      <!-- <br> -->
      <h2>{{ userInfo.user_name }}</h2>
    </div>
    <el-form :model="form" label-position="left" ref="form" style="width: 30%">
      <el-form-item label="用户名" prop="nick_name">
        <el-input v-model="userInfo.nick_name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="user_name">
        <el-input v-model="userInfo.user_name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          placeholder="不修改密码请留空"
          v-model="form.password"
          autocomplete="off"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="pwd_confirm">
        <el-input
          show-password
          placeholder="不修改密码请留空"
          v-model="form.pwd_confirm"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="footer">
      <!-- <el-button @click="onReset">取 消</el-button> -->
      <el-button type="primary" @click="submit">提 交</el-button>
    </div>
  </div>
</template>

<script>
import local from "../../utils/localStroage";
import API from "@/api";
export default {
  name: "information",
  data() {
    return {
      form: {
        password: "",
        pwd_confirm: "",
      },
      avatarUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  computed: {
    userInfo() {
      return local.get("userInfo");
    },
  },
  methods: {
    submit() {
      if (this.form.password == "" && this.form.pwd_confirm == "") {
        this.$message({
          type: "success",
          message: "未修改密码！",
        });
      } else {
        API.chgPwdMenuList({
          password: this.form.password,
          pwd_confirm: this.form.pwd_confirm,
        })
          .then((res) => {
            if(!res.code){
              this.form = res.data;
              this.$message({
                type: "success",
                message: "密码修改成功！",
              });
              this.exit();
            }else{
              this.$message.error(res.msg);
            }
            
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "密码修改失败！",
            });
          });
      }
    },
    // 登出
    exit() {
      local.remove("userInfo");
      local.remove("role");
      this.$store.dispatch("removeAllTag");
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  .avatar {
    width: 30%;
    text-align: center;
  }
  .footer {
    margin-top: 30px;
    margin-left: 330px;
  }
}
</style>
